<!--
 * @Descripttion: 
 * @Author: JayShen
 * @Date: 2021-02-27 18:12:19
 * @LastEditors: drf
 * @LastEditTime: 2023-08-23 14:07:35
-->
<template>
  <div id="app">
    <router-view> </router-view>
    <!-- <dv-full-screen-container class="all-bg">
      <router-view> </router-view>
    </dv-full-screen-container> -->
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
};
</script>

<style lang="less">
#app {
  // #dv-full-screen-container {
  //   overflow: initial;
  //   width: 100% !important;
  //   position: static;
  // }
}

html {
  width: 1920px;
  height: 1080px;
  margin: 0;
  padding: 0;
  // background: linear-gradient(270deg, #07122b, #192643 47%, #0e1a34);
  background: #050426;
  // overflow: hidden;
  // overflow-y: hidden;
}
body {
  margin: 0;
  padding: 0;
}
</style>

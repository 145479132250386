<!--
 * @Descripttion: 中屏幕 左侧数据
 * @Author: JayShen
 * @Date: 2021-03-01 17:02:36
 * @LastEditors: drf
 * @LastEditTime: 2023-09-11 15:47:45
-->
<template>
  <div class="left-data">
    <div class="left-row1">
      <ShadowBox class="left-row1-box1" title="订单类型" line-color="#2CCEAF">
        <Echart
          v-if="orderTypeList.length"
          :options="brandType(orderTypeList)"
          style="width: 100%; height: 200px; margin-top: 10px"
        />
        <div class="text">
          <div class="item">
            <div
              class="item-item"
              v-for="(itemO, indexO) in orderTypeList"
              :key="indexO"
            >
              <template>
                {{ itemO.text }}
                <div
                  class="block"
                  :style="'background:' + orderColor[indexO]"
                ></div>
              </template>
            </div>
            <!-- <div class="item-item" v-if="orderTypeList[1].value" style="margin-right: 20px">
              {{ orderTypeList[1].text }}
              <div class="block" style="background: #3daaeb"></div>
            </div> -->
          </div>
          <!-- <div class="item">
            <div class="item-item" v-if="orderTypeList[2].value" style="margin-right: 20px">
              {{ orderTypeList[2].text }}
              <div class="block" style="background: #fcce48"></div>
            </div>
            <div class="item-item" v-if="orderTypeList[3].value">
              {{ orderTypeList[3].text }}
              <div class="block" style="background: #ff7d7f"></div>
            </div>
          </div> -->
        </div>
      </ShadowBox>
      <ShadowBox title="客单价占比" line-color="#FCCE48" class="left-row1-box2">
        <div class="box2-number">
          <Echart
            v-if="
              allData.indentAvgPriceList && allData.indentAvgPriceList.length
            "
            :options="colorComposition(allData.indentAvgPriceList)"
            style="width: 95%; height: 255px"
          />
          <div class="num">
            <div
              v-for="(item, index) in allData.indentAvgPriceList"
              :key="index"
            >
              {{ (item.percentage * 100).toFixed(0) }}%
            </div>
            <!-- <div>12%</div>
            <div>12%</div>
            <div>12%</div> -->
          </div>
        </div>
        <!-- <div class="box2-text">
          <div>客单价</div>
          <div>占比</div>
        </div> -->
      </ShadowBox>
    </div>
    <div class="left-row2">
      <ShadowBox title="社区保供" line-color="#01bb3f ">
        <dv-scroll-board
          v-if="
            allData.equpimentAddressList && allData.equpimentAddressList.length
          "
          :config="ppsRankList(allData.equpimentAddressList)"
          style="width: 100%; height: 210px; margin-top: 27px"
        />
      </ShadowBox>
      <ShadowBox title="增加就业" line-color="#2DD3B3">
        <div class="row2-right-text">
          <div>岗位(个)</div>
          <div>月</div>
        </div>
        <Echart
          :options="
            jobList(
              allData.jointOperatorRegisterMap &&
                allData.jointOperatorRegisterMap.y
                ? allData.jointOperatorRegisterMap.y.map((item) => {
                    return item.registerQuantity;
                  })
                : []
            )
          "
          style="width: 110%; height: 260px"
        />
      </ShadowBox>
    </div>
    <div class="left-row3">
      <ShadowBox
        class="left-row1-box1"
        title="品类销售额占比"
        line-color="#FF7D7F"
      >
        <dv-active-ring-chart
          v-if="allData.indentClassifyList && allData.indentClassifyList.length"
          :config="salesProportion(allData.indentClassifyList)"
          class="left-row1-box1__chart"
          style="width: 58%; height: 65%; margin-left: 20px"
        />
        <div
          class="row3-text"
          v-if="allData.indentClassifyList && allData.indentClassifyList.length"
        >
          <div class="row3-text-left">
            <div
              class="row3-item"
              v-for="(item, index) in allData.indentClassifyList.slice(
                0,
                Math.ceil(allData.indentClassifyList.length / 2)
              )"
              :key="index"
            >
              <div class="row3-item-text" style="text-align: left">
                {{ item.classifyName }}
              </div>
              <div
                class="square"
                :style="'background:' + colorList[index]"
              ></div>
            </div>
          </div>
          <div class="row3-text-left" v-if="allData.indentClassifyList[3]">
            <div
              class="row3-item"
              v-for="(item, index) in allData.indentClassifyList.slice(
                Math.ceil(allData.indentClassifyList.length / 2)
              )"
              :key="index"
            >
              <div class="row3-item-text" style="text-align: left">
                {{ item.classifyName }}
              </div>
              <div
                class="square"
                :style="'background:' + colorList[index + 3]"
              ></div>
            </div>
          </div>
        </div>
      </ShadowBox>
      <ShadowBox class="left-row3-box2" title="合作品牌" line-color="#FCCE48">
        <div class="row3-box2-whole">
          <div class="row">
            <div class="row-item">
              <img
                src="@/assets/image/brand/fulinmen.png"
                style="width: 62px; height: 40px"
              />
            </div>
            <div class="row-item">
              <img
                src="@/assets/image/brand/jinlongyu.png"
                style="width: 50px; height: 34px"
              />
            </div>
            <div class="row-item">
              <img
                src="@/assets/image/brand/daohuaxiang.png"
                style="width: 70px; height: 26px"
              />
            </div>
            <div class="row-item">
              <img
                src="@/assets/image/brand/wuchang.png"
                style="width: 64px; height: 26px"
              />
            </div>
          </div>
          <div class="row">
            <div class="row-item">
              <img
                src="@/assets/image/brand/lijinji.png"
                style="width: 62px; height: 40px"
              />
            </div>
            <div class="row-item">
              <img
                src="@/assets/image/brand/km.png"
                style="width: 56px; height: 33px"
              />
            </div>
            <div class="row-item">
              <img
                src="@/assets/image/brand/shoutao.png"
                style="width: 55px; height: 30px"
              />
            </div>
            <div class="row-item">
              <img
                src="@/assets/image/brand/chubang.png"
                style="width: 53px; height: 33px"
              />
            </div>
          </div>
          <div class="row">
            <div class="row-item">
              <img
                src="@/assets/image/brand/luhua.png"
                style="width: 62px; height: 29px"
              />
            </div>
            <div class="row-item">
              <img
                src="@/assets/image/brand/yanzhifang.png"
                style="width: 61px; height: 28px"
              />
            </div>
            <div class="row-item">
              <img
                src="@/assets/image/brand/zhongliang.png"
                style="width: 58px; height: 26px"
              />
            </div>
            <div class="row-item">
              <img
                src="@/assets/image/brand/haitian.png"
                style="width: 53px; height: 33px"
              />
            </div>
          </div>
        </div>
      </ShadowBox>
    </div>
  </div>
</template>

<script>
import { findCenterScreenDataLeft } from "@/service/api";
import {
  brandType,
  colorComposition,
  jobList,
  ppsRankList,
  salesProportion,
} from "./options";
export default {
  name: "CenterLeft",
  props: {
    allData: {
      type: Object,
      default: () => {},
    },
    orderTypeList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      brandType,
      colorComposition,
      jobList,
      ppsRankList,
      salesProportion,
      orderColor: ["#33ca65", "#3daaeb", "#fcce48", "#ff7d7f"],
      colorList: [
        "#33ca65",
        "#315eef",
        "#e7a976",
        "#60caae",
        "#60caae",
        "#fcce48",
      ],
      list: [
        {
          classifyName: "1",
        },
        {
          classifyName: "2",
        },
        {
          classifyName: "3",
        },
        {
          classifyName: "4",
        },
        {
          classifyName: "5",
        },
      ],
    };
  },
  // created() {
  //   this.getCenterScreenDataLeft();
  // },
  // mounted() {
  //   const centerLeftTimerNum = 1000 * 60 * 60;
  //   this.centerLeftTimer = setInterval(() => {
  //     this.getCenterScreenDataLeft();
  //   }, centerLeftTimerNum);
  // },

  methods: {},
};
</script>

<style lang="less" scoped >
.left-data {
  // display: inline-block;
  .left-row1 {
    display: flex;
    .left-row1-box1 {
      position: relative;
      &__chart {
        position: absolute;
        top: 30%;
        right: 10%;
      }
      .text {
        display: flex;

        color: #ace5cb;
        font-size: 20px;
        margin-top: 8px;
      }
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
      .item-item {
        display: flex;
        align-items: center;
        line-height: 19px;
      }
      .block {
        width: 14px;
        height: 14px;
        margin-left: 3px;
      }
      // &__chart {
      //   position: absolute;
      //   top: 30%;
      //   right: 10%;
      // }
      // &__legend {
      //   position: absolute;
      //   top: 30%;
      //   left: 10%;
      //   &__text {
      //     font-size: 20px;
      //     font-weight: 400;
      //     text-align: left;
      //     color: #c5e4ff;
      //     margin-bottom: 20px;
      //   }
      //   :last-child {
      //     margin-bottom: 0px;
      //   }
      // }
    }
    .left-row1-box2 {
      .box2-number {
        display: flex;
        color: #ade6cc;
        font-size: 20px;
        // margin: 20px;

        .num {
          // height: max-content;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 213px;
          margin-top: 18px;
          margin-left: -10px;
        }
      }
      .box2-text {
        display: flex;
        justify-content: space-between;
        color: #ade6cc;
        font-size: 20px;
        margin-top: -25px;
        padding: 0 0px 0 0px;
      }
    }
    .left-row1-box3 {
      position: relative;
      &__chart {
        position: absolute;
        top: 25%;
        right: 23%;
      }
      &__legend {
        position: absolute;
        top: 30%;
        &__text {
          font-size: 32px;
          font-weight: 400;
          text-align: left;
          color: #c5e4ff;
          line-height: 38px;
          margin-bottom: 35px;
        }
        :last-child {
          margin-bottom: 0px;
        }
      }
      &__legend__right {
        left: 5%;
      }
      &__legend__left {
        top: 35%;
        right: 5% !important;
      }
    }
  }
  .left-row2 {
    display: flex;
    margin-top: 20px;
    // .left-row2-box1__flex {
    //   display: flex;
    //   &__title {
    //     font-size: 20px;
    //     font-weight: 400;
    //     text-align: left;
    //     color: #c5e4ff;
    //     margin-left: 40px;
    //   }
    // }
    .row2-right-text {
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      color: #ade6cc;
      line-height: 22px;
      margin-bottom: -30px;
      margin-top: 20px;
    }
  }
  .left-row3 {
    display: flex;
    margin-top: 20px;
    .left-row1-box1 {
      position: relative;
      &__chart {
        position: absolute;
        top: 27%;
        right: 18%;
        // /deep/ .dv-digital-flop {
        //   width: 88px;
        //   height: 42px;
        // }
        /deep/ .active-ring-name {
          color: #ade6cc;
          font-size: 20px !important;
        }
      }
      .row3-text {
        font-size: 20px;
        color: #ade6cc;
        line-height: 22px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        // margin-top: 110px;
        position: absolute;
        bottom: 20px;
        width: 90%;

        .row3-text-left {
          .row3-item {
            margin-top: 15px;
            display: flex;
            align-items: center;
            line-height: 19px;
            .row3-item-text {
              // width: 100px;
              text-align: right;
            }
            .square {
              width: 4px;
              height: 16px;
              background: #fcce48;
              margin-left: 4px;
            }
          }
        }
      }
      // &__legend {
      //   position: absolute;
      //   top: 30%;
      //   left: 10%;
      //   &__text {
      //     font-size: 20px;
      //     font-weight: 400;
      //     text-align: left;
      //     color: #c5e4ff;
      //     margin-bottom: 20px;
      //   }
      //   :last-child {
      //     margin-bottom: 0px;
      //   }
      // }
    }
  }
  .left-row3-box2 {
    .row3-box2-whole {
      display: flex;
      flex-direction: column;
      .row {
        display: flex;
        justify-content: space-between;
        margin-top: 14px;
        .row-item {
          width: 92px;
          height: 64px;
          border: 1px solid #009632;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
/deep/ .dv-scroll-board .rows .row-item {
  color: #ade6cc;
  font-size: 18px !important;
}
/deep/ .dv-scroll-board .header {
  font-size: 20px;
}
</style>
/*
 * @Descripttion: 
 * @Author: JayShen
 * @Date: 2021-03-01 20:05:08
 * @LastEditors: drf
 * @LastEditTime: 2023-09-11 15:37:50
 */
// import echarts from "echarts";

// 订单类型--饼图
export const brandType = (data) => ({
    tooltip: {
        trigger: 'item',
    },
    grid: {
        left: "0%",
        right: "0%",
        bottom: "0%",
        top: 30,
        containLabel: true,
    },

    legend: {
        show: true,
        type: "plain",
        orient: 'vertical',
        left: 'left',
        data: ['wechatmini', 'iOS', 'Android', '智能柜'],
        textStyle: {
            color: '#ace5cb',
            fontSize: 20
        }
    },
    series: [{
        type: 'pie',
        // top: 1,
        radius: ['45%', '70%'],
        // avoidLabelOverlap: false,
        itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 0
        },
        label: {
            show: true,
            position: 'outside',
            fontSize: 22,
            formatter(param) {
                // correct the percentage
                return param.value + '%';
            }
        },
        labelLine: {
            show: true,
            length: 20,
            length2: 30,
        },
        // emphasis: {
        //     label: {
        //         show: true,
        //         fontSize: 40,
        //         fontWeight: 'bold'
        //     }
        // },
        // labelLine: {
        //     show: false
        // },
        color: ['#33ca65', '#3daaeb', '#fcce48', '#FF7D7F'],
        data: data.map((item) => {
            return {
                ...item,

            }
        }),

    }]
})
// 客群占比--柱形图
export const colorComposition = (data ) => ({
    tooltip: {
        trigger: "axis",
        axisPointer: {
            type: "shadow",
        },
    },
    grid: {
        left: "0%",
        right: "5%",
        bottom: "0%",
        top: "5%",
        containLabel: true,
    },
    xAxis: {
        show: false,
    },
    yAxis: {
        type: "category",
        data: data.map((item, index) => {
            return item.priceInterval

        }).reverse(),
        splitLine: {
            show: false,
        },
        axisLine: {
            show: false // 隐藏y轴的轴线
        },
        axisTick: {
            show: false // 隐藏y轴的刻度线
        },
        axisLabel: {
            textStyle: {
                color: "#ade6cc",
                fontSize: 20,

            },
            // align: 'right',
            // margin: {
            //     // right: 2 // 增加左侧外边距
            // }
        },
    },
    series: [{
        name: "",
        type: "bar",
        data: data.map((item) => {
            return item.percentage
        }).reverse(),
        // 柱形图数据展示
        // label: {
        //     show: true,
        //     position:'top',
        //     valueAnimation: true,
        //     color: '#C5E4FF',
        //     formatter: '{c}%'
        // },
        // 柱形图背部阴影
        showBackground: true,
        backgroundStyle: {
            color: '#024c2c'
        },
        itemStyle: {
            normal: {
                color: function (params) {
                    var colorList = ['#33CA65','#E7A976','#FCCE48', '#3DAAEB', '#FF7D7F', '#2CCEAF',];
                    return colorList[params.dataIndex]
                },
                barBorderRadius: 4,
                textStyle: {
                    color: '#ADE6CC',
                    fontSize: 20
                }
            }
        },
    },],
    barWidth: 17,
})
// 社区保供排行
export const ppsRankList = (data) => ({
    header: ['智能柜', '社区', '街道'],
    headerHeight: 42,
    // columnWidth: 46,
    rowNum: 4,
    headerBGC: '#009632',
    oddRowBGC: '#050426',
    evenRowBGC: '#033029',
    // evenRowBGC: '',
    // columnWidth: [160],
    align: ['left', 'left', 'left'],
    data: data.map((item, index) => {
        return [
            item.code,
            item.community,
            item.street
        ]
    })
})
// 增加就业--折线图
export const jobList = (data) => ({
    tooltip: {
        trigger: "axis",
        axisPointer: {
            type: "shadow",
        },
        formatter: '{b0}月: {c0}个'
    },
    grid: {
        left: '40px' // 设置图表向右移动20%
    },
    xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
        axisLabel: {
            color: '#ADE6CC', // 设置 X 轴文字颜色为黑色
            fontSize: 18 // 设置 X 轴文字大小为 12px
        },
        axisLine: {
            lineStyle: {
                color: '#02522c' // 设置 X 轴轴线颜色为黑色
            }
        },
        axisTick: {
            show: false // 隐藏x轴的刻度线
        },
    },
    yAxis: {
        type: 'value',
        axisLabel: {
            color: '#ADE6CC', // 设置 X 轴文字颜色为黑色
            fontSize: 18 // 设置 X 轴文字大小为 12px
        },
        axisLine: {
            lineStyle: {
                color: '#02522c' // 设置 X 轴轴线颜色为黑色
            }
        },
        axisTick: {
            show: false // 隐藏y轴的刻度线
        },
        splitLine: {
            lineStyle: {
                color: '#02522c' // 设置 Y 轴辅助线颜色为黑色
            }
        }
    },
    series: [
        {
            data: data,
            type: 'line',
            smooth: true,
            showSymbol: false,// 隐藏折线图上的点
            areaStyle: {
                color: '#195e42'
            },
            lineStyle: {
                color: '#32c864'
            },
            // label:{
            //     show:true
            // }
        }
    ],
})
// 品类销售额占比
export const salesProportion = (data) => ({
    
    data: data.map(item => {
        return {
            value: item.percentage*100,
            name: item.classifyName
        }
    }),
    lineWidth: 20,
    radius: "75%",
    activeRadius: "80%",
    digitalFlopStyle: {
        fontSize: 32,

        fill: "#ade6cc",
    },
    color: ['#33ca65', '#315eef', '#e7a976', '#60caae', '#e96074', '#fcce48'],
    legend: {
        show: false,
    },
})

/*
 * @Descripttion: 入口文件
 * @Author: JayShen
 * @Date: 2021-02-27 18:12:19
 * @LastEditors: drf
 * @LastEditTime: 2023-09-04 10:34:59
 */
import Vue from 'vue'
import App from './App.vue'
import dataV from '@jiaminghi/data-view'
// 适配flex
// import './utils/flexible.js';
import plugins from '@/plugins/clobalComponent.js'
import router from './router'
// import store from "./store";
import '@/assets/iconFont/iconfont.css'
import VueAliplayerV2 from 'vue-aliplayer-v2'
Vue.use(plugins)
Vue.use(dataV)
Vue.use(VueAliplayerV2)
Vue.config.productionTip = false
new Vue({
  router,
  // store,
  render: h => h(App),

}).$mount('#app')
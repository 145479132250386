<!--
 * @Descripttion: 
 * @Author: JayShen
 * @Date: 2021-02-28 16:02:41
 * @LastEditors: drf
 * @LastEditTime: 2023-09-11 09:25:52
-->
<template>
  <div class="shadow-box" :style="`width:${width}px;height:${height}px`">
    <h3 v-if="title" class="shadow-box__title">
      <div class="title-left">
        <VerticalLine v-if="showLine" :line-color="lineColor" />
        <div>{{ title }}</div>
      </div>
      <div class="text-whole" v-if="textList && textList.length">
        <div class="title-text" v-for="(item, index) in textList" :key="index">
          {{ item }}
          <div
            style="width: 4px; height: 18px; margin-left: 4px"
            :style="'background:' + colorList[index]"
          ></div>
        </div>
      </div>
    </h3>
    <div class="shadow-box__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShadowBox",
  props: {
    title: {
      type: String,
      default: "",
    },
    showLine: {
      type: Boolean,
      default: true,
    },
    lineColor: {
      type: String,
      default: "#fcce48",
    },
    width: {
      type: Number,
      default: 448,
    },
    height: {
      type: Number,
      default: 304,
    },
    colorList: {
      type: Array,
      default: () => [],
    },
    textList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="less" scoped>
.shadow-box {
  box-sizing: border-box;
  // background: rgba(25, 38, 67, 0.8);
  border: 1px solid #009632;
  box-shadow: 0px 0px 20px 0px #009632 inset;
  margin-right: 22px;
  flex: 1;
  &__title {
    font-size: 26px;
    font-weight: 400;
    text-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    color: #ade6cc;
    text-align: left;
    margin: 20px 0 0px 20px;
    display: flex;
    align-items: center;
    line-height: 26px;
    justify-content: space-between;
    .title-left {
      display: flex;
      align-items: center;
    }
    .text-whole {
      display: flex;
    }
    .title-text {
      font-size: 18px;
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
  }
  &__content {
    // height: 100%;
    padding: 0 20px;
  }
}
</style>
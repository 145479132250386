/*
 * @Descripttion: 
 * @Author: JayShen
 * @Date: 2021-02-27 21:50:09
 * @LastEditors: drf
 * @LastEditTime: 2023-09-20 09:22:09
 */
import request from '@/utils/request'
import { mesBaseApi } from "@/utils/tootls"
// import {
//     getToken
// } from '@/utils/token'
// 中间大屏左边数据
export function findCenterScreenDataLeft(params) {
    return request({
        url: 'old/Isonoe/client/synthesis/findCenterScreenDataLeft',
        method: 'post',
        data: params
    })
}

// 左边大屏数据
export function findLeftScreenData(params) {
    return request({
        url: '/act/client/dashboard/left',
        method: 'post',
        data: params
    })
}

// 中间大屏数据
export function findCenterScreenData(params) {
    return request({
        url: '/act/client/dashboard/middle',
        method: 'post',
        data: params
    })
}


// 右边大屏数据
export function findRightScreenData(params) {
    return request({
        url: '/act/client/dashboard/right',
        method: 'post',
        data: params
    })
}

// 大屏数据
export const findLargeScreenInfo = (params) => {
    return request({
        url: `${mesBaseApi}/smapis/platform/dashboard/findLargeScreenInfo/unauth`,
        method: 'post',
        data: params
    })
}
// 右侧大屏登录
export const login = (params) => {
    return request({
        url: `https://api.zaihour.com/iot-auth/account/login`,
        method: 'post',
        data: params
    })
}
//场景概况
export const profiler = (params) => {
    return request({
        url: `https://api.zaihour.com/iot-admin/scene/profiler`,
        method: 'get',
        data: params
    })
}
//监控概况
export const camera = (params) => {
    return request({
        url: `https://api.zaihour.com/iot-admin/camera-push/list`,
        method: 'get',
        data: params
    })
}
//虫情识别数据
export const insect = (params) => {
    return request({
        url: `https://api.zaihour.com/iot-admin/insect-data/list`,
        method: 'get',
        data: params
    })
}
//耗肥、耗药数据
export const weight = (params) => {
    return request({
        url: `https://api.zaihour.com/iot-admin//weight-data/current-year`,
        method: 'get',
        data: params
    })
}


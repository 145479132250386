<!--
 * @Descripttion: 
 * @Author: Nikehu
 * @Date: 2022-11-10 14:53:33
 * @LastEditors: Nikehu
 * @LastEditTime: 2022-11-10 17:40:35
-->
<template>
  <div class="title-bg">
    <div class="short"></div>
    <div class="middle"></div>
    <div class="long"></div>
    <div class="title">
      <slot></slot>
    </div>
    <div class="long"></div>
    <div class="middle"></div>
    <div class="short"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.title-bg {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 600;

  .title {
    background: linear-gradient(0deg, #8fcdff 3%, #ecf6ff 98%);
    text-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    -webkit-background-clip: text;
    color: transparent;
  }

  div {
    margin: 0 5px;
  }

  .short {
    width: 4px;
    height: 4px;
    background-color: #4389f1;
  }
  .middle {
    width: 4px;
    height: 9px;
    background-color: #4389f1;
  }
  .long {
    width: 4px;
    height: 14px;
    background-color: #4389f1;
  }
}
</style>
/*
 * @Descripttion: 路由配置
 * @Author: JayShen
 * @Date: 2021-03-02 14:38:46
 * @LastEditors: drf
 * @LastEditTime: 2023-08-21 11:15:43
 */
import Router from 'vue-router'
import Vue from 'vue'
import CenterScreen from "@/page/centerScreen"

import Home from "@/page/home"
Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [{
        path: '/',
        name: 'home',
        component: Home,
    },
    {
        path: '/centerScreen',
        name: 'centerScreen',
        component: CenterScreen,
    },
    
    ]
})
export default router
/*
 * @Descripttion: 
 * @Author: JayShen
 * @Date: 2021-03-02 11:11:21
 * @LastEditors: drf
 * @LastEditTime: 2023-09-11 15:48:59
 */
import {
    pieColor,
    // pieColor2
} from "@/utils/echartColor";
export const jobList = (series,XData) => ({
    tooltip: {
        trigger: "axis",
        axisPointer: {
            type: "shadow",
        },
        formatter: function (params) {
            var tooltipContent = '';
            params.forEach(function (item) {
                tooltipContent += item.seriesName + ': ' + item.value + '千克' + '<br>';
            });
            return tooltipContent;
        }
    },
    grid: {
        left: '55px' // 设置图表向右移动20%
    },
    // legend: {
    //     data: ['系列1', '系列2', '系列3']
    // },
    xAxis: {
        type: 'category',
        boundaryGap: false,
        data: XData,
        axisLabel: {
            color: '#ADE6CC', // 设置 X 轴文字颜色为黑色
            fontSize: 16, // 设置 X 轴文字大小为 12px
            // interval: 0  // 设置刻度标签显示间隔为0
        },
        axisLine: {
            lineStyle: {
                color: '#02522c' // 设置 X 轴轴线颜色为黑色
            }
        },
        axisTick: {
            show: false // 隐藏x轴的刻度线
        },

    },
    yAxis: {
        type: 'value',
        axisLabel: {
            color: '#ADE6CC', // 设置 X 轴文字颜色
            fontSize: 16, // 设置 X 轴文字大小
            interval: 2  // 设置刻度标签显示间隔为0
        },
        axisLine: {
            lineStyle: {
                color: '#02522c' // 设置 X 轴轴线颜色
            }
        },
        axisTick: {
            show: false // 隐藏y轴的刻度线
        },
        splitLine: {
            lineStyle: {
                color: '#02522c' // 设置 Y 轴辅助线颜色
            }
        }
    },
    series: series
})

export const jobList1 = (series1,XData1) => ({
    tooltip: {
        trigger: "axis",
        axisPointer: {
            type: "shadow",
        },
        formatter: function (params) {
            var tooltipContent = '';
            params.forEach(function (item) {
                tooltipContent += item.seriesName + ': ' + item.value + '千克' + '<br>';
            });
            return tooltipContent;
        }
    },
    grid: {
        left: '40px' // 设置图表向右移动20%
    },
    xAxis: {
        type: 'category',
        boundaryGap: false,
        data: XData1,
        axisLabel: {
            color: '#ADE6CC', // 设置 X 轴文字颜色为黑色
            fontSize: 16, // 设置 X 轴文字大小为 12px
            interval: 0  // 设置刻度标签显示间隔为0
        },
        axisLine: {
            lineStyle: {
                color: '#02522c' // 设置 X 轴轴线颜色为黑色
            }
        },
        axisTick: {
            show: false // 隐藏x轴的刻度线
        },

    },
    yAxis: {
        type: 'value',
        axisLabel: {
            color: '#ADE6CC', // 设置 X 轴文字颜色
            fontSize: 16, // 设置 X 轴文字大小
            interval: 0  // 设置刻度标签显示间隔为0
        },
        axisLine: {
            lineStyle: {
                color: '#02522c' // 设置 X 轴轴线颜色
            }
        },
        axisTick: {
            show: false // 隐藏y轴的刻度线
        },
        splitLine: {
            lineStyle: {
                color: '#02522c' // 设置 Y 轴辅助线颜色
            }
        }
    },
    series: series1
})

// 虫情识别--饼图
export const insectType = (data,colorList) => ({
    tooltip: {
        trigger: 'item',
        formatter: '{c0}个'
    },
    // grid: {
    //     left: "0%",
    //     right: "0%",
    //     bottom: "0%",
    //     top: 30,
    //     containLabel: true,
    // },

    // legend: {
    //     show: true,
    //     type: "plain",
    //     orient: 'vertical',
    //     left: 'left',
    //     data: ['wechatmini', 'iOS', 'Android', '智能柜'],
    //     textStyle: {
    //         color: '#ace5cb',
    //         fontSize: 20
    //     }
    // },
    series: [{
        type: 'pie',
        // top: 1,
        // radius: ['45%', '70%'],
        // avoidLabelOverlap: false,
        itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 0
        },
        label: {
            show: true,
            position: 'outside',
            fontSize: 22,
            formatter(param) {
                // correct the percentage
                return param.value + '%';
            }
        },
        labelLine: {
            show: false,
            length: 33,
            length2: 20,
        },
        // emphasis: {
        //     label: {
        //         show: true,
        //         fontSize: 40,
        //         fontWeight: 'bold'
        //     }
        // },
        // labelLine: {
        //     show: false
        // },
        color: colorList,
        data: data.map((item) => {
            return item.pestNum
        }),

    }]
})

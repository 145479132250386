<!--
 * @Descripttion: 中屏幕 右侧数据
 * @Author: JayShen
 * @Date: 2021-03-01 17:02:36
 * @LastEditors: drf
 * @LastEditTime: 2023-09-20 10:41:46
-->
<template>
  <div class="right-data">
    <div class="right-row1">
      <!-- <img src="@/assets/image/map.png" style="width: 100%; height: 100%" /> -->
      <div class="row1-top">
        <div class="top-item">
          <div class="item-left">
            <div class="left-top">
              <img
                src="@/assets/image/icon.png"
                style="width: 28px; height: 31px"
              />
            </div>
            <div class="row1-bottom-text" @click="open()">大棚</div>
          </div>
          <div class="item-middle">
            <div class="row1-top-text">
              {{
                farmList && farmList[1]
                  ? farmList.filter((item) => {
                      return item.type == "2";
                    })[0].number
                  : ""
              }}
            </div>
            <div class="row1-bottom-text">总数(个)</div>
          </div>
          <div class="item-line"></div>
          <div class="item-right">
            <div class="row1-top-text">
              {{
                farmList && farmList[1]
                  ? farmList.filter((item) => {
                      return item.type == "2";
                    })[0].area
                  : ""
              }}
            </div>
            <div class="row1-bottom-text">总面积(亩)</div>
          </div>
        </div>
        <div class="top-item">
          <div class="item-left">
            <div class="left-top">
              <img
                src="@/assets/image/icon.png"
                style="width: 30px; height: 30px"
              />
            </div>
            <div class="row1-bottom-text">稻田</div>
          </div>
          <div class="item-middle">
            <div class="row1-top-text">
              {{
                farmList && farmList[1]
                  ? farmList.filter((item) => {
                      return item.type == "3";
                    })[0].number
                  : ""
              }}
            </div>
            <div class="row1-bottom-text">总数(个)</div>
          </div>
          <div class="item-line"></div>
          <div class="item-right">
            <div class="row1-top-text">
              {{
                farmList && farmList[1]
                  ? farmList.filter((item) => {
                      return item.type == "3";
                    })[0].area
                  : ""
              }}
            </div>
            <div class="row1-bottom-text">总面积(亩)</div>
          </div>
        </div>
        <div class="top-item">
          <div class="item-left">
            <div class="left-top">
              <img
                src="@/assets/image/icon.png"
                style="width: 33px; height: 33px"
              />
            </div>
            <div class="row1-bottom-text">菜地</div>
          </div>
          <div class="item-middle">
            <div class="row1-top-text">
              {{
                farmList && farmList[1]
                  ? farmList.filter((item) => {
                      return item.type == "4";
                    })[0].number
                  : ""
              }}
            </div>
            <div class="row1-bottom-text">总数(个)</div>
          </div>
          <div class="item-line"></div>
          <div class="item-right">
            <div class="row1-top-text">
              {{
                farmList && farmList[1]
                  ? farmList.filter((item) => {
                      return item.type == "4";
                    })[0].area
                  : ""
              }}
            </div>
            <div class="row1-bottom-text">总面积(亩)</div>
          </div>
        </div>
      </div>
      <div class="row1-bottom" id="play-1">
        <!-- source="https://yushangtianyuanvideo.zaihour.com:27096/live/cameraid/1000010%2421/substream/1.m3u8" -->
        <!-- :options="playerOptions" -->
        <div v-for="(item, index) in cameraList" :key="index">
          <VueAliplayerV2
            @ready="handleReady"
            @onM3u8Retry="onM3u8Retry(index)"
            :options="item"
            :ref="'aliplayer' + index"
            style="width: 280px; height: 158px"
          ></VueAliplayerV2>
          <!-- <video
            :id="'playWind' + index"
            :src="item.source"
            controls
            playsInline
            webkit-playsinline
            muted
            autoplay
          ></video> -->
        </div>
      </div>
    </div>
    <div class="right-row2">
      <ShadowBox
        title="用肥量"
        line-color="#2DD3B3"
        :colorList="colorList"
        :textList="textList"
        :width="292"
      >
        <div class="row2-right-text">
          <div>单位(千克)</div>
          <div>月</div>
        </div>
        <Echart
          :options="jobList(series, XData[0])"
          style="width: 110%; height: 260px"
        />
      </ShadowBox>
      <ShadowBox
        title="用药量"
        line-color="#2DD3B3"
        :colorList="colorList"
        :textList="textList1"
        :width="292"
      >
        <div class="row2-right-text">
          <div>单位(千克)</div>
          <div>月</div>
        </div>
        <Echart
          :options="jobList1(series1,XData1[0])"
          style="width: 110%; height: 260px"
        />
      </ShadowBox>
      <ShadowBox
        class="row3-box"
        title="虫情识别"
        line-color="#67A6E0"
        :width="292"
      >
        <div class="row3-box-text">
          采集：{{ insectList[0] ? insectList[0].sampleTime : "" }}
        </div>
        <div class="row3-box-text">
          识别：{{ insectList[0] ? insectList[0].analysisTime : "" }}
        </div>
        <div class="row3-box-line"></div>
        <div class="row3-box-square">
          <div class="row3-box-square-item">
            <img
              :src="insectList[0] ? insectList[0].path : ''"
              style="width: 122px; height: 122px; object-fit: cover"
              @click="showFullScreen"
            />
            <div v-if="isFullScreen" class="fullscreen-overlay">
              <img
                :src="clickedImageUrl"
                class="fullscreen-image"
                @click="closeFullScreen"
              />
              <button @click="closeFullScreen" class="close-button">
                关闭
              </button>
            </div>
          </div>
          <div
            v-if="
              insectList[0] &&
              insectList[0].analysisResult &&
              insectList[0].analysisResult[0]
            "
            class="row3-box-square-item"
            style="border: 0"
          >
            <Echart
              :options="insectType(insectList[0].analysisResult, colorList)"
              style="width: 100%; height: 100%; border: 0"
            />
            <!-- <img
              :src="insectList[0] ? insectList[0].analysisResult[0].image : ''"
              style="width: 122px; height: 132px"
            /> -->
          </div>
        </div>
        <div
          v-if="
            insectList[0] &&
            insectList[0].analysisResult &&
            insectList[0].analysisResult[0]
          "
          class="row3-box-bottom"
        >
          <div
            v-for="(item, index) in insectList[0].analysisResult"
            :key="index"
            class="row3-box-bottom-item"
          >
            {{ item.pest_name }}
            <div
              style="width: 4px; height: 16px; margin-left: 4px"
              :style="'background:' + colorList[index]"
            ></div>
          </div>
        </div>
      </ShadowBox>
    </div>
  </div>
</template>

<script>
import { jobList, jobList1, insectType } from "./options";
import {
  findLargeScreenInfo,
  login,
  profiler,
  camera,
  insect,
  weight,
} from "@/service/api";
import { setToken } from "@/utils/token";
import EZUIKit from "../../../static/ezuikit";
// import VueAliplayerV2 from 'vue-aliplayer-v2'
export default {
  name: "CenterRight",
  //  components: {
  //   VueAliplayerV2
  // },
  props: {
    allData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      jobList,
      jobList1,
      insectType,
      farmList: [],
      cameraList: [],
      insectList: [],
      fertilizerList: [],
      pesticideList: [],
      // data: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],

      series: [],
      series1: [],
      textList: [],
      textList1: [],
      colorList: [
        "#FCCE48",
        "#e96074",
        "#01BB3F",
        "#FF7D7F",
        "#315EEF",
        "#E7A976",
      ],
      playerOptions: {
        id: "play-1",
        source:
          "https://yushangtianyuanvideo.zaihour.com:27096/live/cameraid/1000010%2421/substream/1.m3u8",
        width: "280px",
        height: "158px",
        autoplay: true,
        isLive: true,
        rePlay: false,
      },
      playerInstance: null,
      isFullScreen: false,
      clickedImageUrl: "",
      XData: [],
      XData1:[],
    };
  },
  created() {
    this.init();

    // this.$refs['aliplayer0'].play();
  },
  mounted() {
    this.initPlayer();
  },
  watch: {
    "cameraList.length": function (val) {
      setTimeout(() => {
        //设置延迟执行
        console.log("watch");

        this.open();
      }, 0);
    },
  },
  computed: {
    playsinline() {
      let ua = navigator.userAgent.toLocaleLowerCase();
      // x5内核
      if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
        return false;
      } else {
        // ios端
        return true;
      }
    },
  },
  methods: {
    initPlayer() {
      // this.playerInstance = new EZUIKit.EZUIPlayer("playWind");
      // this.playerInstance = new EZUIKit.EZUIPlayer("playWind");
      // this.playerInstance = new EZUIKit.EZUIPlayer("playWind");
      // this.playerInstance = new EZUIKit.EZUIPlayer("playWind");
    },
    open() {
      console.log(1);
      // console.log(this.$refs.aliplayer0[0], "this.$refs");
      // this.$refs.aliplayer0[0].mute(0);
      this.$refs.aliplayer0[0].setVolume(0);
      this.$refs.aliplayer1[0].setVolume(0);
      this.$refs.aliplayer2[0].setVolume(0);
      this.$refs.aliplayer0[0].play();
      this.$refs.aliplayer1[0].play();
      this.$refs.aliplayer2[0].play();
    },
    onM3u8Retry(index) {
      this.open();
    },
    init() {
      login({ tenantId: "22", userName: "demo", password: "Ztp3pogC" }).then(
        (res) => {
          if (res.data.code == 200) {
            // this.$store.commit("changeLogin",res.data);
            // localStorage.setItem('Token', res.data);
            setToken(res.data.data);
            profiler().then((Res) => {
              if (Res.data.code == 200) {
                this.farmList = Res.data.data;
              }
            });
            camera().then((Res) => {
              if (Res.data.code == 200) {
                this.cameraList = Res.data.data.map((item, index) => {
                  return {
                    id: "play-1",
                    source: item.cameraPushAddress,
                    // cover: item.cameraCoverImage,
                    width: "280px",
                    height: "158px",
                    autoplay: false,
                    isLive: true,
                    rePlay: false,
                    format: "m3u8",
                    muted: true,
                  };
                });
              }
            });
            insect().then((insectRes) => {
              if (insectRes.data.code == 200) {
                this.insectList = insectRes.data.data.map((item) => {
                  return {
                    ...item,
                    analysisResult: JSON.parse(item.analysisResult),
                  };
                });
                console.log(
                  this.insectList[0].analysisResult,
                  "insectList[0].analysisResult[0]"
                );
              }
            });
            weight({ materialType: 1 }).then((weightRes) => {
              if (weightRes.data.code == 200) {
                let fertilizerList = weightRes.data.data;
                let valueList = [];
                this.textList = [];
                for (let i in fertilizerList) {
                  valueList.push(fertilizerList[i]);
                  this.textList.push(i);
                }

                valueList.forEach((itemV, indexV) => {
                  // let dataList = [];
                  // for (let i = 1; i < 13; i++) {
                  //   dataList.push({
                  //     month: i,
                  //     dataValue: null,
                  //   });
                  // }

                  // dataList.forEach((itemD, indexD) => {
                  //   itemV.forEach((itemChild) => {
                  //     if (itemD.month == itemChild.month) {
                  //       itemD.dataValue = itemChild.dataValue;
                  //     }
                  //   });
                  // });

                  // 取最近6个月的数据
                  itemV = itemV.slice(-6);
                });
                valueList.forEach((itemF, indexF) => {
                  this.series.push({
                    data: itemF.map((itemChild) => {
                      return itemChild.dataValue;
                    }),
                    name: this.textList[indexF],
                    type: "line",
                    smooth: true,
                    showSymbol: false, // 隐藏折线图上的点
                    // areaStyle: {
                    //     color: '#195e42' //折线图阴影颜色
                    // },
                    lineStyle: {
                      color: this.colorList[indexF],
                    },
                  });
                  this.XData.push(
                    itemF.map((itemChild) => {
                      return itemChild.month;
                    })
                  );
                });
              }
            });
            weight({ materialType: 2 }).then((weightRes) => {
              if (weightRes.data.code == 200) {
                let fertilizerList = weightRes.data.data;
                let valueList = [];
                this.textList1 = [];
                for (let i in fertilizerList) {
                  valueList.push(fertilizerList[i]);
                  this.textList1.push(i);
                }
                valueList.forEach((itemV, indexV) => {
                  // 取最近6个月的数据
                  itemV = itemV.slice(-6);
                });
                valueList.forEach((itemF, indexF) => {
                  this.series1.push({
                    data: itemF.map((itemChild) => {
                      return itemChild.dataValue;
                    }),
                    name: this.textList1[indexF],
                    type: "line",
                    smooth: true,
                    showSymbol: false, // 隐藏折线图上的点
                    // areaStyle: {
                    //     color: '#195e42' //折线图阴影颜色
                    // },
                    lineStyle: {
                      color: this.colorList[indexF],
                    },
                    
                  });
                  this.XData1.push(
                    itemF.map((itemChild) => {
                      return itemChild.month;
                    })
                  );
                });
              }
            });
          }
        }
      );
    },
    handleReady(e) {
      console.log(`ready`, e);
      this.open();
      // this.$refs.aliplayer0[0].fullscreenService.requestFullScreen()
    },
    showFullScreen(event) {
      this.isFullScreen = true;
      this.clickedImageUrl = event.target.src;
    },
    closeFullScreen() {
      this.isFullScreen = false;
    },
  },
};
</script>

<style lang="less" scoped >
.right-data {
  margin-left: 22px;
  // display: inline-block;
  .right-row1 {
    display: flex;
    flex-direction: column;
    width: 916px;
    height: 628px;
    border: 1px solid #009632;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px 0px #009632 inset;
    background: url("../../assets/image/map.png") top center no-repeat;
    background-size: 100% 100%;
    .row1-top {
      display: flex;
      margin-top: 20px;
      margin: 20px 20px;
      justify-content: space-between;
      // width: 100%;
      .top-item {
        display: flex;
        width: 280px;
        height: 84px;
        background: rgba(3, 48, 41, 0.5);
        border: 1px solid #009632;
        .item-left {
          flex: 1;
          height: 84px;
          background: rgba(0, 150, 50, 0.7);
          border: 1px solid #009632;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .item-middle {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .item-line {
          width: 1px;
          height: 67px;
          background: #009632;
          margin: auto;
        }
        .item-right {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .row1-top-text {
          font-size: 30px;
          font-weight: bold;
          color: #ade6cc;
          line-height: 26px;
          margin-bottom: 8px;
        }
        .row1-bottom-text {
          font-size: 16px;
          font-weight: bold;
          color: #ade6cc;
          line-height: 18px;
        }
      }
    }
    .row1-bottom {
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 327px;
      display: flex;
      justify-content: space-between;
    }
  }
  .right-row2 {
    display: flex;
    margin-top: 20px;
    .right-row2-box1__flex {
      display: flex;
      &__title {
        font-size: 20px;
        font-weight: 400;
        text-align: left;
        color: #c5e4ff;
        margin-left: 30px;
      }
    }
    .right-row2-box2 {
      /deep/ .unit-label {
        opacity: 0;
      }
      /deep/ .unit-text {
        opacity: 0;
      }
    }
  }
  .right-row3 {
    display: flex;
    margin-top: 10px;
    .right-row3-box3 {
      box-sizing: border-box;
      width: 906px;
      height: 362px;
      background: rgba(25, 38, 67, 0.8);
      border: 1px solid #1572be;
      box-shadow: 0px 0px 20px 0px #005fac inset;
      margin-right: 10px;
      &__title {
        display: flex;
        align-items: center;
        margin: 30px 20px;
        font-size: 26px;
        font-weight: 400;
        background: linear-gradient(0deg, #8fcdff 3%, #ecf6ff 98%);
        text-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
        -webkit-background-clip: text;
        color: transparent;
        text-align: left;
        margin-left: 10px;
      }
    }
  }
}
.row2-right-text {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  color: #ade6cc;
  line-height: 22px;
  margin-bottom: -30px;
  margin-top: 20px;
}
.row3-box {
  font-size: 16px;
  color: #ade6cc;
  line-height: 22px;
  .row3-box-text {
    &:first-child {
      margin-top: 12px;
    }
    margin-top: 8px;
  }
  .row3-box-line {
    width: 256px;
    height: 1px;
    background: #02522c;
    text-align: center;
    margin-top: 10px;
  }
  .row3-box-square {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    .row3-box-square-item {
      width: 124px;
      height: 124px;
      // background: rgba(0, 150, 50, 0.7);
      border: 1px solid #009632;
      box-sizing: border-box;
    }
  }
  .row3-box-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    .row3-box-bottom-item {
      display: flex;
      align-items: center;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
/deep/ .shadow-box {
  margin-right: 20px;
}
/deep/ .shadow-box__content {
  padding: 0 14px;
}
/deep/ video {
  object-fit: fill;
}

/* 样式用于实现全屏展示效果 */
.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 1920px !important;
  height: 1080px !important;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullscreen-image {
  max-width: 90%;
  max-height: 90%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
<!--
 * @Descripttion: 
 * @Author: JayShen
 * @Date: 2021-02-27 18:12:19
 * @LastEditors: drf
 * @LastEditTime: 2023-09-21 11:06:46
-->
<template>
  <div class="center">
    <header>
      <!-- <VerticalLine />得体智能供应链服务平台<VerticalLine /> -->
      <div class="title-img">
        <img
          src="@/assets/image/title-back.png"
          style="width: 100%; height: 64px"
        />
        <div class="title-font font-left">供销直选</div>
        <div class="title-font font-center">数字供销</div>
        <div class="title-font font-right">供销农场</div>
      </div>
    </header>
    <main>
      <CenterLeft :allData="centerData" :orderTypeList="orderTypeList" />
      <div class="line"></div>
      <CenterRight :allData="centerData" />
    </main>
  </div>
</template>

<script>
import { formatTime } from "@/utils/tootls";
import CenterLeft from "@/components/centerLeft";
import CenterRight from "@/components/centerRight";
import { findLargeScreenInfo, login, profiler } from "@/service/api";
import { setToken } from "@/utils/token";
export default {
  name: "Center",
  components: {
    CenterLeft,
    CenterRight,
  },
  data() {
    return {
      centerData: {},
      orderType: ["Android", "iOS", "WeChatMini", "智能柜"],
      orderTypeList: [
        {
          label: "Android",
          value: "",
          text: "Android",
        },
        {
          label: "iOS",
          value: "",
          text: "iOS",
        },
        {
          label: "WeChatMini",
          value: "",
          text: "小程序",
        },
        {
          label: "智能柜",
          value: "",
          text: "智能柜",
        },
      ],
      centerTimer: {},
      centerLeftTimer: null,
    };
  },
  created() {
    this.init();
  },
  mounted() {
    // 20分钟
    const centerLeftTimerNum = 1000 * 60 * 20;
    this.centerLeftTimer = setInterval(() => {
      this.init();
    }, centerLeftTimerNum);
  },
  destroyed() {
    clearInterval(this.centerLeftTimer);
    this.centerLeftTimer = null;
  },
  methods: {
    init() {
      this.getCenterAllData();
    },
    getCenterAllData() {
      findLargeScreenInfo().then((res) => {
        this.centerData = res.data.data;
        // let indentSourcePercentageList =
        //   res.data.data.indentSourcePercentageList;
        this.centerData.indentSourcePercentageList.forEach((itemI) => {
          this.orderTypeList.forEach((itemO) => {
            if (itemI.sourceName == itemO.label) {
              itemO.value = itemI.percentage * 100;
            }
          });
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.center {
  // width: 1920px;
  // height: 1080px;
  // margin: 29px 0px 20px 20px;
  width: 100%;
}
header {
  margin-bottom: 15px;
  margin-top: 29px;
  .title {
    position: relative;
  }
  .title-font {
    position: absolute;
    background: linear-gradient(0deg, #5fd486 1%, #e5f3ff 99%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    // color: #f9f9f9;
    line-height: 60px;
    text-wrap: nowrap;
  }
  .font-left {
    font-size: 38px;
    font-weight: bold;
    left: 306px;
    top: 44px;
  }
  .font-center {
    font-size: 50px;
    font-weight: bold;
    left: 861px;
    top: 17px;
  }
  .font-right {
    font-size: 38px;
    font-weight: bold;
    left: 1467px;
    top: 44px;
  }
}
main {
  display: flex;
  margin: 0px 20px 0px 20px;
}
.line {
  min-width: 1px;
  height: 952px;
  background: #009632;
}
</style>

<!--
 * @Descripttion: 
 * @Author: JayShen
 * @Date: 2021-03-02 15:06:12
 * @LastEditors: drf
 * @LastEditTime: 2023-08-21 11:17:01
-->
<template>
  <div>
    <centerScreen></centerScreen>
  </div>
</template>

<script>
import centerScreen from "../centerScreen/index.vue";
export default {
  name: "Home",
  components: {
    centerScreen,
  },
  data() {
    return {};
  },

  methods: {},
};
</script>

<style lang="less" scoped>
</style>